import React, { useState } from 'react';
import { Container, Flex } from '@radix-ui/themes';
import { Link, useLocation } from 'react-router-dom';
import SkipNavigation from 'components/common/skipnav';
import { sitemap } from 'app/constants';
import './style.scss';

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname.split('?')[0];

  const handleMouseOver = () => setIsMenuOpen(true);
  const handleMouseOut = () => setIsMenuOpen(false);

  const isLinkActive = link => new RegExp(`^${link}(/|$)`).test(currentPath);

  const isMenuActive = (depth1Url, depth2Urls) => {
    return isLinkActive(depth1Url) || depth2Urls?.some(item => isLinkActive(item.url));
  };

  return (
    <>
      <SkipNavigation />
      <header>
        <Container size="4">
          <Flex align="center" className="container">
            <h1 className="logo">
              <Link to="/" title="메인으로 이동">
                SW미래채움
              </Link>
            </h1>
            <nav id="menu" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} onKeyUp={handleMouseOver}>
              <ul className="depth1">
                {sitemap.map(({ depth1, depth2 }) => (
                  <li key={depth1.url}>
                    <Link
                      to={depth1.url}
                      className={`sub-title ${isMenuActive(depth1.url, depth2) ? 'active' : ''}`}
                      aria-label={isMenuActive(depth1.url, depth2) ? `선택됨 ${depth1.name}` : depth1.name}
                      aria-live="polite"
                    >
                      {depth1.name}
                    </Link>
                    {isMenuOpen && depth2.length > 0 && (
                      <div className="depth2 sub-menu">
                        <ul>
                          {depth2
                            .filter(item => !item.hide)
                            .map(subItem => (
                              <li key={subItem.url}>
                                <Link
                                  onKeyDown={e => {
                                    if (e.keyCode === 9 && subItem.url === '/promotion') {
                                      handleMouseOut();
                                    }
                                  }}
                                  onClick={() => setIsMenuOpen(false)}
                                  to={subItem.url}
                                  className={`sub-menu-item ${isLinkActive(subItem.url) ? 'active' : ''}`}
                                  aria-label={isLinkActive(subItem.url) ? `선택됨 ${subItem.name}` : subItem.name}
                                  aria-live="polite"
                                >
                                  {subItem.name}
                                </Link>
                              </li>
                            ))}
                        </ul>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </nav>
          </Flex>
        </Container>
        <div
          className={`menu-wrap ${isMenuOpen ? 'open' : ''}`}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          onKeyUp={handleMouseOver}
        ></div>
      </header>
    </>
  );
}
