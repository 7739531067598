import React from 'react';
import Visual from 'components/common/visual';
import { Box, Container, Section } from '@radix-ui/themes';
import Breadcrumbs from 'components/common/breadcrumbs';
import PageTitle from 'components/layout/pageTitle';

import './style.scss';

export default function Privacy() {
  return (
    <>
      <Visual category="privacy" title="개인정보처리방침" />
      <Breadcrumbs />
      <Section p="0" mb={'var(--space-35)'} className="privacy-policy">
        <Container size="4">
          <PageTitle icon="privacy" title="개인정보처리방침" />
          <Box mb="40px">
            <h4 className="title5">개인정보 보호정책</h4>
            <p className="body2-description">
              'SW미래채움'은(이하'회사'는)고객님들의 개인정보를 중요시하며,"정보통신망이용촉진 및 정보보호"에 관한 법률을 준수하고 있습니다.
              <br />
              개인정보취급방침을 통하여 고객님께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한
              조치가 취해지고 있는지 알려드립니다.
            </p>
          </Box>
          <Box mb="48px">
            <ol className="roundbox">
              <li className="body2-description">01. 수집하는 개인정보 항목</li>
              <li className="body2-description">02. 개인정보의 수집 및 이용목적</li>
              <li className="body2-description">03. 개인정보의 보유 및 이용기간</li>
              <li className="body2-description">04. 개인정보 관리책임자 안내 및 민원서비스</li>
            </ol>
          </Box>
          <div>
            <strong className="body2 bold">01.수집하는 개인정보 항목</strong>
            <p className="body2-description">
              SW미래채움에서는 뉴스레터 신청,사업제한,전문가DB신청 등을 위하여 아래와 같은 개인정보를 수집하고 있습니다.
            </p>
            <dl className="dots sm">
              <dt className="body2-description">(1) 필수 수집 정보</dt>
              <dd className="body2-description">성명, 연락처, 이메일, 고객 의사 내용, 뉴스레터 정보</dd>
              <dt className="body2-description">(2) 기타 수집 정보</dt>
              <dd className="body2-description">
                이용자 IP주소, 방문일시, 쿠키, 서비스이용정보 등 불량회원의 부정 이용방지와 비인간 사용방지, 통계학적 분석 등을 위하여
                사용됩니다.
              </dd>
              <dt className="body2-description">(3) 개인정보 수집방법</dt>
              <dd className="body2-description">홈페이지, 서양양식, 팩스, 전화, 이벤트/관람 응모, 신청게시판</dd>
              <dt className="body2-description">
                (4) SW미래채움은 고객의 사전 동의 없이는 고객 개인정보를 공개하지 않으며, 수집된 정보는 아래와 같이 이용하고 있습니다.
              </dt>
              <dt className="body2-description">(5) 미성년의 개인정보보호</dt>
              <dd className="body2-description">
                만 14세 미안의 어린이는 자신에 대한 정보를 다란 사람에게 함부로 보내면 안되며, 개입 및 보내기 전 반드시 부모님의 허락을
                받아야 합니다.
              </dd>
            </dl>
          </div>
          <hr />
          <div>
            <strong className="body2 bold">02. 개인정보의 수집 및 이용 목적</strong>
            <ol>
              <li className="body2-description">
                (1) 사업제안 등 뉴스레터 최신 정보안내를 위한 정확한 전자우편 고지사항 전달 경호 사항을 확보하기 위하여 사용 됩니다.
              </li>
              <li className="body2-description">(2) 신규 서비스나 개발 및 마케팅 활용에 사용됩니다.</li>
              <li className="body2-description">
                (3) SW미래체움에 관련 베너 링크되어 있는 웹사이트에서 개인정보를 수집하는 해우이에 대해서는 본 웹사이트[개인정보취급방침]에
                적용되지 않음을 알려드립니다.
              </li>
            </ol>
          </div>
          <hr />
          <div>
            <strong className="body2 bold">03. 개인정보의 보유 및 이용기간</strong>
            <ol>
              <li className="body2-description">
                (1) 원칙적으로, 개인정보 수집 및 이용목적이 달성된 이후에는 해당 정보를 지체 없이 파기합니다.
                <span>단, 다음 정보에 대해서는 명시한 기간 동안 보존합니다.</span>
              </li>
              <li className="body2-description">
                (2) 보존항목 : 뉴스레터 신청 정보, 사업제한 작성 정보, 전문가DB신청 정보, 접속 IP정보
                <span>보존근거 : 신청 취소 및 사유 확인, 작성 글 확인 용도, 연령대/기간별/접속통계 활용 용도</span>
                <span>보존기간 : 3년</span>
              </li>
            </ol>
          </div>
          <hr />
          <div>
            <strong className="body2 bold">04. 개인정보 관리책임자 안내 및 민원 서비스</strong>
            <ol className="info">
              <li>
                <span className="body2-description">
                  (1) SW미래채움에서는 개인정보를 보호하고, 개인정보보호 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및
                  개인정보관리책임자를 지정하고 있습니다.
                </span>
                <ul className="roundbox bg-gray info-items">
                  <li className="body2 member">
                    담당자 : 전남정보문화산업진흥원 <strong>송용환 책임</strong>
                  </li>
                  <li className="body2 call">
                    전화번호 : <strong>061-339-6975</strong>
                  </li>
                </ul>
              </li>
              <li className="body2-description">
                <span>(2) 기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.</span>
                <ul className="dots sm">
                  <li>
                    개인분쟁조정위원회 (
                    <a href="http://www.1336.or.kr/" target="_blank" rel="noopener noreferrer">
                      www.1336.or.kr
                    </a>
                    )
                  </li>
                  <li>
                    정보보호마크인증위원회 (
                    <a href="http://www.eprivacy.or.kr" target="_blank" rel="noopener noreferrer">
                      www.eprivacy.or.kr
                    </a>
                    )
                  </li>
                  <li>
                    대검찰청 첨단범죄수사과 (
                    <a href="https://www.spo.go.kr/site/spo/main.do" target="_blank" rel="noopener noreferrer">
                      https://www.spo.go.kr/site/spo/main.do
                    </a>
                    , 02-3480-2000 )
                  </li>
                  <li>
                    경찰청 사이버테러대응센터 (
                    <a href="https://ecrm.police.go.kr/minwon/main" target="_blank" rel="noopener noreferrer">
                      https://ecrm.police.go.kr/minwon/main
                    </a>
                    , 02-392-0330 )
                  </li>
                </ul>
              </li>
            </ol>
          </div>
        </Container>
      </Section>
    </>
  );
}
