import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button, Heading, Text } from '@radix-ui/themes';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Link } from 'react-router-dom';

import { localList } from 'app/constants';
import './style.scss';
import 'swiper/css';

export const MainSlider = ({ sliderList }) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const progressCircle = useRef(null);
  const [swiperRef, setSwiperRef] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const onAutoplayTimeLeft = (s, t, progress) => {
    progressCircle.current.style.setProperty('--progress', 1 - progress);
  };

  const play = useCallback(() => {
    swiperRef?.autoplay.start();
    setIsPlaying(true);
  }, [swiperRef]);

  const pause = useCallback(() => {
    swiperRef?.autoplay.stop();
    setIsPlaying(false);
  }, [swiperRef]);

  const handlePlaying = () => {
    isPlaying ? pause() : play();
  };

  useEffect(() => {
    if (swiperRef) {
      pause();
      updateInertAttribute(swiperRef); // Apply inert attribute on init
      swiperRef.on('slideChange', function () {
        updateInertAttribute(this);
      });
    }
  }, [swiperRef, pause]);

  useEffect(() => {
    if (swiperRef && sliderList) {
      pause();
      swiperRef.loopDestroy();
      swiperRef.update();
      swiperRef.loopCreate();
      swiperRef.slideToLoop(0);
      updateInertAttribute(swiperRef); // Ensure inert attribute is set after update
    }
  }, [sliderList, swiperRef]);

  const updateInertAttribute = swiper => {
    swiper.slides.forEach((slide, index) => {
      if (index === swiper.activeIndex || index === swiper.activeIndex + 1 || index === swiper.activeIndex - 1) {
        slide.removeAttribute('inert');
      } else {
        slide.setAttribute('inert', '');
      }
    });
  };
  return (
    <Box className="main-slider">
      <Swiper
        slidesPerView={'auto'}
        spaceBetween={36}
        centeredSlides={true}
        loop
        modules={[Autoplay, Pagination, Navigation]}
        pagination={{
          type: 'fraction',
          el: '.swiper-pagination',
          formatFractionCurrent: number => number
        }}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current
        }}
        onBeforeInit={swiper => {
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false
        }}
        onAutoplayTimeLeft={onAutoplayTimeLeft}
        onSwiper={setSwiperRef}
      >
        {sliderList?.map((item, index) => (
          <SwiperSlide key={index} className={`banner ${localList.find(data => data.name === item.region)?.en}`}>
            <Link
              className="slide-item"
              to={item.board === '교육정보' ? `/education/detail/${item.postNo}` : `/event/detail/${item.postNo}`}
            >
              <div className="text-wrap">
                <Text className="chips">{item.board}</Text>
                <Heading as="h3" mt="var(--space-5)" className="title3 ellipsis">
                  {item.title}
                </Heading>
                <Text as="p" className="body1">
                  {new Date(item.startDate).getFullYear() > 2000
                    ? `일정 : ${item.startDate ? new Date(item.startDate).toISOString().split('T')[0] : 'N/A'} ~ ${
                        item.endDate ? new Date(item.endDate).toISOString().split('T')[0] : 'N/A'
                      }`
                    : ''}
                </Text>
              </div>
              <div className="image-wrap noData">{item.fileUrl && <img src={item.fileUrl} alt={item.title} />}</div>
              <Text className={`chips local body1 ${localList.find(data => data.name === item.region)?.en}`}>{item.region}</Text>
            </Link>
          </SwiperSlide>
        ))}
        <div className="utility">
          <Button className="slider-prev" ref={prevRef}>
            Prev
          </Button>
          <div className="swiper-pagination"></div>
          <div className="progress-wrap">
            <div className="progress" slot="container-end">
              <svg viewBox="0 0 33 32" ref={progressCircle}>
                <circle cx="16.5" cy="16" r="15" />
              </svg>

              <Button className={!isPlaying ? 'play' : 'pause'} onClick={handlePlaying}>
                {!isPlaying ? '재생' : '정지'}
              </Button>
            </div>
          </div>
          <Button className="slider-next" ref={nextRef}>
            Next
          </Button>
        </div>
      </Swiper>
    </Box>
  );
};

export default MainSlider;
