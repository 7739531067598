import React from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Flex, Strong, Text } from '@radix-ui/themes';

import LocalSort from 'components/common/localSort';
import BoardSearchBox from 'components/board/boardSearchBox';
import BoardImageItems from 'components/board/boardImageItems';
import Pagination from 'components/common/pagination';
import NoData from 'components/common/noData';
import { selectUserSelectedLocalCode, setUserSelectedLocal } from 'store/slices/common';
import { useDispatch, useSelector } from 'react-redux';
import { localList } from 'app/constants';

export default function BoardImage({ boardType, localFilter, itemType, tableBody, loading }) {
  // hooks
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const userSelectedLocalCode = useSelector(selectUserSelectedLocalCode);

  // 지역 선택 method
  const setRegion = region => {
    searchParams.set('page', 1);
    searchParams.set('searchType', '');
    searchParams.set('search', '');
    setSearchParams(searchParams);
    dispatch(setUserSelectedLocal(localList.find(local => local.code === region).name));
  };

  // 페이지 이동 method
  const handlePageChange = pageNo => {
    const totalPages = tableBody?.pagination.totalPage;
    if (pageNo >= 1 && pageNo <= totalPages) {
      searchParams.set('page', pageNo);
      setSearchParams(searchParams);
    }
  };

  // 상세 페이지 이동 method
  const handleCellClick = code => {
    navigate(`${location.pathname}/detail/${code}`, {
      state: {
        boardType,
        pageNo: Number(searchParams.get('page')) || 1,
        pageSize: 8,
        searchType: searchParams.get('searchType'),
        search: searchParams.get('search'),
        inOrder: 'DESC',
        region: userSelectedLocalCode
      }
    });
  };

  // 검색 method
  const getSearchText = ({ type, text }) => {
    searchParams.set('page', 1);
    searchParams.set('searchType', type);
    searchParams.set('search', text);
    setSearchParams(searchParams);
  };
  return (
    <>
      {localFilter && <LocalSort setRegion={setRegion} />}
      <Box className="board-list">
        <Flex justify="between" align="center" mt="var(--space-12)" height="48px">
          <Text className="board-counter">
            Total :<Strong>&nbsp;{tableBody?.pagination.totalCount?.toLocaleString()}</Strong>건
          </Text>
          <Flex justify="end" align="center" gap="2" className="board-search-box">
            <BoardSearchBox getSearchText={getSearchText} />
          </Flex>
        </Flex>
        {loading ? null : tableBody?.pagination?.totalCount > 0 ? (
          <>
            <Box mt="4">
              <BoardImageItems itemType={itemType} tableData={tableBody.list} onCellClick={handleCellClick} />
            </Box>
            <Flex justify="center" mt="var(--space-12)">
              <Pagination total={tableBody?.pagination.totalPage} handlePageChange={handlePageChange} />
            </Flex>
          </>
        ) : (
          <NoData />
        )}
      </Box>
    </>
  );
}
