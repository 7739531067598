import React, { useCallback, useEffect, useState } from 'react';
import { Box, Card, Container, Section, TabNav, Flex } from '@radix-ui/themes';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Visual from 'components/common/visual';
import Breadcrumbs from 'components/common/breadcrumbs';
import PageTitle from 'components/layout/pageTitle';
import LocalSort from 'components/common/localSort';
import CenterIntroduction from 'components/localCenter/CenterIntroduction';
import OrganizationChart from 'components/localCenter/OrganizationChart';
import MapDirections from 'components/localCenter/MapDirections';
import NoData from 'components/common/noData';

import { instance } from 'api/axios.instance';
import { localList } from 'app/constants';
import { selectUserSelectedLocalCode, setUserSelectedLocal } from 'store/slices/common';
import './style.scss';

// 소개 > 지역센터소개 페이지
export default function LocalCenter() {
  // hooks
  const userSelectedLocalCode = useSelector(selectUserSelectedLocalCode);
  const dispatch = useDispatch();

  // state
  const [localData, setLocalData] = useState();
  const pathname = window.location.pathname.toLowerCase();
  const baseURL = process.env.REACT_APP_API_BASE;

  //tab
  const tabs = [
    { path: '/localcenter', label: '센터소개', to: '/localCenter' },
    { path: '/localorganization', label: '조직도', to: '/localOrganization' },
    { path: '/localmap', label: '찾아오시는 길', to: '/localMap' }
  ];

  // 지역 선택 method
  const setRegion = region => {
    dispatch(setUserSelectedLocal(localList.find(local => local.code === region).name));
  };

  // api 조회
  const performFetch = useCallback(async () => {
    const response = await instance(`${baseURL}/center/${userSelectedLocalCode}`, {
      method: 'GET'
    });

    if (response.data.status === 200) {
      setLocalData(response.data.data);
    }
  }, [baseURL, userSelectedLocalCode]);

  // mount 혹은 지역 변경 시 호출
  useEffect(() => {
    if (!userSelectedLocalCode || userSelectedLocalCode === 'ALL') return;
    performFetch();
  }, [userSelectedLocalCode, performFetch]);

  return (
    <>
      <Visual category="introduction" title="소개" />
      <Breadcrumbs category="introduction" />
      <Section p="0" mb={'var(--space-35)'} className="local-center">
        <Container>
          <PageTitle icon="local" title="지역센터소개" />
          <Box mb="120px">
            <Card variant="classic">
              <Box py="8px" px="40px">
                <LocalSort hideAll={true} setRegion={setRegion} />
              </Box>
            </Card>
          </Box>

          {!userSelectedLocalCode || userSelectedLocalCode === 'ALL' ? (
            <NoData />
          ) : (
            <>
              <Flex align="center" justify="between" mb="var(--space-15)">
                <Box className="center-logo">
                  <img
                    src={require(`assets/images/logo/logo-${userSelectedLocalCode}.svg`)}
                    alt={`${localList.find(local => local.code === userSelectedLocalCode).name}센터 sw미래채움 로고`}
                  />
                </Box>
                <Box>
                  <img src={require('assets/images/icon/icon-school-2.svg').default} alt="SW미래채움 센터 아이콘" />
                </Box>
              </Flex>
              <TabNav.Root className="tabslist">
                {tabs.map(({ path, label, to }) => (
                  <TabNav.Link key={path} asChild active={pathname === path}>
                    <Link to={to} className="body1" aria-label={pathname === path ? `선택됨 ${label}` : label} aria-live="polite">
                      {label}
                    </Link>
                  </TabNav.Link>
                ))}
              </TabNav.Root>

              {pathname === '/localcenter' && <CenterIntroduction localData={localData?.info} />}
              {pathname === '/localorganization' && <OrganizationChart localData={localData?.organization} />}
              {pathname === '/localmap' && <MapDirections localData={localData?.map} />}
            </>
          )}
        </Container>
      </Section>
    </>
  );
}
