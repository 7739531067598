import React from 'react';
import { Box, Table, Theme } from '@radix-ui/themes';
import classNames from 'classnames';
import './style.scss';
import { useLocation, Link, useSearchParams } from 'react-router-dom';

export const ListType = ({ tableHeader, tableData, title, totalCount }) => {
  // hooks
  const [searchParams] = useSearchParams();
  const currentPage = Number(searchParams.get('page')) || 1;
  const location = useLocation();

  const formatCommas = number => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  return (
    <Box className="board-text-items">
      <Theme radius="none">
        <Table.Root variant="surface" size="3">
          <caption>{`${title} 게시판 목록`}</caption>
          {/*<summary>{`${title} 게시글 목차를 구성하는 테이블 각 내용의 번호, 지역, 제목, 첨부파일, 조회수, 등록일의 내용을 전달한다.`}</summary>*/}
          <Table.Header>
            <Table.Row align="center">
              {tableHeader?.map((header, index) => (
                <Table.ColumnHeaderCell key={index} justify="center" width={header.width}>
                  {header.label}
                </Table.ColumnHeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {tableData.map((post, rowIndex) => (
              <Table.Row key={rowIndex} align="center" className={classNames({ 'row-notice': post.isFixed })}>
                {tableHeader?.map((header, colIndex) => (
                  <Table.Cell key={colIndex} justify={header.left ? 'start' : 'center'} data-title={header.label}>
                    {/* Header for rowNum */}
                    {header.key === 'rowNum' && post.isFixed ? (
                      <img src={require('assets/images/icon/icon-megaphone.svg').default} alt="공지사항" />
                    ) : header.key === 'isFile' && post[header.key] === true ? (
                      <img src={require('assets/images/icon/icon-clip.svg').default} alt="첨부파일" />
                    ) : header.key === 'viewCount' ? (
                      formatCommas(post[header.key])
                    ) : header.key === 'createDate' || header.key === 'startDate' || header.key === 'endDate' ? (
                      new Date(post.createDate)?.toISOString().split('T')[0]
                    ) : header.key === 'title' ? (
                      <Link to={`${location.pathname}/detail/${post.postNo}`}>{post.title}</Link>
                    ) : (
                      post[header.key]
                    )}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table.Root>
      </Theme>
    </Box>
  );
};
