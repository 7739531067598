import React from 'react';
import './style.scss';

export default function SkipNavigation() {
  return (
    <>
      <div id="SkipNavigation">
        <p>
          <a href="#contents" tabIndex="1">
            본문 바로가기
          </a>
        </p>
        <p>
          <a href="#menu" tabIndex="2">
            주메뉴 바로가기
          </a>
        </p>
      </div>
    </>
  );
}
