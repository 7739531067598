// 메인 페이지
import Main from 'pages/main';

// 소개 페이지
import Intro from 'pages/intro';
import PromotionSystem from 'pages/promotionSystem';
import LocalCenter from 'pages/localCenter';
import CI from 'pages/ci';

// 교육안내 페이지
import Education from 'pages/education';
import EducationDetail from 'pages/education/Detail';

// 행사안내 페이지
import Event from 'pages/event';
import EventDetail from 'pages/event/Detail';

// 공지사항 페이지
import Notice from 'pages/notice';
import NoticeDetail from 'pages/notice/Detail';

// 홍보자료 페이지
import Promotion from 'pages/promotion';
import PromotionDetail from 'pages/promotion/Detail';

// 개인정보처리방침 페이지
import Privacy from 'pages/privacy';

// 에러 페이지
import Error404 from 'components/common/error/Error404';

const RouteList = [
  {
    path: '/',
    element: <Main />,
    title: 'SW미래채움'
  },
  {
    path: '/intro',
    element: <Intro />,
    title: '사업소개 | 소개 | SW미래채움'
  },
  {
    path: '/ci',
    element: <CI />,
    title: '사업 CI | 소개 | SW미래채움'
  },
  {
    path: '/localCenter',
    element: <LocalCenter />,
    title: regionName => `센터소개  | ${regionName}  | 지역센터소개 | 소개 | SW미래채움`
  },
  {
    path: '/localOrganization',
    element: <LocalCenter />,

    title: regionName => `조직도 | ${regionName} | 지역센터소개 | 소개 | SW미래채움`
  },
  {
    path: '/localMap',
    element: <LocalCenter />,
    title: regionName => `찾아오시는 길 | ${regionName} | 지역센터소개 | 소개 | SW미래채움`
  },
  {
    path: '/promotionSystem',
    element: <PromotionSystem />,
    title: '사업 추진체계 | 소개 | SW미래채움'
  },
  {
    path: '/privacy',
    element: <Privacy />,
    title: '개인정보처리방침 | SW미래채움'
  },
  {
    path: '/education',
    element: <Education />,
    title: regionName => `교육정보 | ${regionName} | 교육안내 | SW미래채움`
  },
  {
    path: '/education/detail/:id',
    element: <EducationDetail />,
    title: regionName => `상세 | 교육정보 | ${regionName} | 교육안내 | SW미래채움`
  },
  {
    path: '/event',
    element: <Event />,
    title: regionName => `행사정보 | ${regionName} | 행사안내 | SW미래채움`
  },
  {
    path: '/event/detail/:id',
    element: <EventDetail />,
    title: regionName => `상세 | 행사정보 | ${regionName} | 행사안내 | SW미래채움`
  },
  {
    path: '/notice',
    element: <Notice />,
    title: regionName => `공지사항 목록 | ${regionName} | 공지사항 | SW미래채움`
  },
  {
    path: '/notice/detail/:id',
    element: <NoticeDetail />,
    title: regionName => `상세 | 공지사항 목록 | ${regionName} | 공지사항 | SW미래채움`
  },
  {
    path: '/promotion',
    element: <Promotion />,
    title: regionName => `홍보자료 목록 | ${regionName} | 홍보자료 | SW미래채움`
  },
  {
    path: '/promotion/detail/:id',
    element: <PromotionDetail />,
    title: regionName => `상세 | 홍보자료 목록 | ${regionName} | 홍보자료 | SW미래채움`
  },
  {
    path: '*',
    element: <Error404 />,
    noHeaderFooter: true
  }
];

export default RouteList;
