import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import { Link } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';

export const BannerSlider = ({ banners }) => {
  console.log(banners);
  return (
    <div style={{ marginTop: '60px', marginBottom: '100px' }}>
      <Swiper
        modules={[Autoplay, Pagination]}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        pagination={{ clickable: true }}
        loop
        slidesPerView={1}
      >
        {banners.map(banner => (
          <SwiperSlide key={banner.id}>
            <Link to={banner.to} title={banner.title} className="slim-banner">
              <img src={banner.src} alt={banner.alt} />
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default BannerSlider;
