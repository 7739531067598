import React, { useEffect, useState } from 'react';
import { Box, Flex, IconButton } from '@radix-ui/themes';
import { useSearchParams } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon, DoubleArrowLeftIcon, DoubleArrowRightIcon } from '@radix-ui/react-icons';

import './style.scss';

export default function Pagination({ total, handlePageChange }) {
  // hooks
  const [searchParams] = useSearchParams();
  const currentPage = Number(searchParams.get('page')) || 1;

  // state
  const [pageNumbers, setPageNumbers] = useState([]);
  const totalPages = total;
  const pageLimit = 5;
  const startPage = Math.max(1, currentPage - Math.floor(pageLimit / 2));
  const endPage = Math.min(totalPages, startPage + pageLimit - 1);

  useEffect(() => {
    const numbers = [];
    for (let i = startPage; i <= endPage; i++) {
      numbers.push(i);
    }
    setPageNumbers(numbers);
  }, [endPage, startPage]);

  return (
    <Box className="pagination">
      <nav aria-label="페이지네이션">
        <Flex as="ul" role="list" gap="var(--space-1)" justify="center">
          {currentPage !== 1 && (
            <>
              {/* 처음으로 */}
              <li>
                <IconButton
                  aria-label="첫 페이지"
                  title="첫 페이지"
                  variant="outline"
                  radius="full"
                  color="gray"
                  onClick={() => handlePageChange(1)}
                >
                  <DoubleArrowLeftIcon width={18} height={18} />
                </IconButton>
              </li>
              {/* 이전 페이지 */}
              <li>
                <IconButton
                  aria-label="이전 페이지"
                  title="이전 페이지"
                  variant="outline"
                  radius="full"
                  color="gray"
                  mr="1"
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <ChevronLeftIcon width={18} height={18} />
                </IconButton>
              </li>
            </>
          )}

          {/* 페이지 목록 */}
          {pageNumbers.map(pageNum => (
            <li key={pageNum}>
              <IconButton
                aria-label={currentPage === pageNum ? `선택된 페이지 ${pageNum}` : `페이지 ${pageNum}`}
                size="1"
                className={`rt-variant-text ${currentPage === pageNum ? 'currentPage' : ''}`}
                onClick={() => handlePageChange(pageNum)}
              >
                {pageNum}
              </IconButton>
            </li>
          ))}

          {currentPage !== totalPages && (
            <>
              {/* 다음 페이지 */}
              <li>
                <IconButton
                  aria-label="다음 페이지"
                  title="다음 페이지"
                  variant="outline"
                  radius="full"
                  color="gray"
                  ml="1"
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <ChevronRightIcon width={18} height={18} />
                </IconButton>
              </li>
              {/* 마지막 페이지 */}
              <li>
                <IconButton
                  aria-label="마지막 페이지"
                  title="마지막 페이지"
                  variant="outline"
                  radius="full"
                  color="gray"
                  onClick={() => handlePageChange(totalPages)}
                >
                  <DoubleArrowRightIcon width={18} height={18} />
                </IconButton>
              </li>
            </>
          )}
        </Flex>
      </nav>
    </Box>
  );
}
