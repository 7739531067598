import React, { useEffect, useState } from 'react';
import { Button, Flex } from '@radix-ui/themes';
import { useSelector } from 'react-redux';

import { localList } from 'app/constants';
import { selectUserSelectedLocal } from 'store/slices/common';
import './style.scss';

export default function LocalSort({ hideAll, setRegion }) {
  // hooks
  const userSelectedLocal = useSelector(selectUserSelectedLocal);

  // state
  const [active, setActive] = useState(null); // 활성화 된 지역 인덱스

  // 지역 선택 method
  const handleClick = i => {
    setActive(i);
    setRegion(localList[i].code);
  };

  // mount 시 선택했던 지역 다시 할당
  useEffect(() => {
    setActive(localList.findIndex(local => local.name === userSelectedLocal));
  }, [userSelectedLocal]);

  return (
    <>
      <Flex justify="between" className="local-sort">
        {localList.map((item, index) => {
          if (hideAll && item.name === '전체') {
            return null;
          }

          const isActive = active === index;
          const label = isActive ? `선택됨 ${item.name}` : item.name;

          return (
            <Button
              key={index}
              radius="full"
              className={'body1 ' + (isActive ? 'active' : '')}
              onClick={() => handleClick(index)}
              aria-label={label}
              aria-live="polite"
            >
              {item.name}
            </Button>
          );
        })}
      </Flex>
    </>
  );
}
