import React, { useEffect } from 'react';
import '@radix-ui/themes/styles.css';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Theme } from '@radix-ui/themes';
import Header from 'components/layout/header';
import Footer from 'components/layout/footer';
import ScrollToTop from './hooks/useScrollTop';
import { useSelector } from 'react-redux';
import RouteList from 'app/router';
import 'assets/scss/tokens/df-user/token.scss';
import { selectUserSelectedLocalCode } from 'store/slices/common';
import { localList } from 'app/constants';
export default function App() {
  const normalizePath = path => path.replace(/\/detail\/\d+$/, '/detail').replace(/\/:\w+/g, '');
  const location = useLocation();
  const userSelectedLocalCode = useSelector(selectUserSelectedLocalCode);
  const regionName = localList.find(local => local.code === userSelectedLocalCode)?.name;
  console.log(regionName);

  useEffect(() => {
    const currentRoute = RouteList.find(route => normalizePath(route.path) === normalizePath(location.pathname));
    if (currentRoute?.title) {
      const title = typeof currentRoute.title === 'function' ? currentRoute.title(regionName) : currentRoute.title;
      document.title = title || '내용없음';
    } else {
      document.title = '내용없음';
    }
  }, [location, regionName]);

  useEffect(() => {
    // 웹접근성 페이지 이동 시 최상단 요소에 포커스 이동
    const root = document.getElementById('root');
    if (root) {
      root.setAttribute('tabindex', '-1');
      root.focus();
    }
  }, [location.pathname]);

  return (
    <div className="App">
      <Theme accentColor={'blue'}>
        <Routes>
          {RouteList.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                <>
                  <ScrollToTop>
                    {!route.noHeaderFooter && <Header />}
                    <main id="contents">{route.element}</main>
                    {!route.noHeaderFooter && <Footer />}
                  </ScrollToTop>
                </>
              }
            />
          ))}
        </Routes>
      </Theme>
    </div>
  );
}
