import React, { useRef, useEffect, useState } from 'react';
import { Box, Button } from '@radix-ui/themes';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import { useSelector } from 'react-redux';
import { selectUserSelectedLocal } from 'store/slices/common';
import defaultImage from 'assets/images/common/noimg.png';
import 'swiper/css';
import './style.scss';

export const SubSlider = ({ handleSlideChange, name, item, index }) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const swiperRef = useRef(null);

  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const onSlide = swiper => {
    const currentActiveIndex = swiper.activeIndex;
    handleSlideChange(index, currentActiveIndex);
    setIsBeginning(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
  };

  const center = useSelector(selectUserSelectedLocal);

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(0); // 첫 번째 슬라이드로 강제 이동
      setIsBeginning(true);
      setIsEnd(item.length <= 1);
    }
  }, [item.length]);

  return (
    <>
      <Box className="sub-slider">
        <Swiper
          ref={swiperRef}
          modules={[Pagination, Navigation]}
          pagination={item.length > 1 ? { clickable: true } : false}
          navigation={
            item.length > 1
              ? {
                  prevEl: prevRef.current,
                  nextEl: nextRef.current
                }
              : false
          }
          onBeforeInit={swiper => {
            if (item.length > 1) {
              swiper.params.navigation.prevEl = prevRef.current;
              swiper.params.navigation.nextEl = nextRef.current;
            }
          }}
          onSlideChange={onSlide}
          onSwiper={swiper => {
            swiperRef.current = swiper;
          }}
          key={JSON.stringify(item)}
        >
          {item.map((file, fileIndex) => (
            <SwiperSlide className="slider-item index" key={fileIndex}>
              <img
                alt={!file?.fileUrl ? `등록된 이미지가 없습니다` : `${name} 이미지 ${fileIndex + 1}`}
                src={!file?.fileUrl ? defaultImage : file.fileUrl}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        {item.length > 1 && (
          <>
            <Button className="slider-prev" ref={prevRef} style={{ display: isBeginning ? 'none' : 'block' }}>
              Prev
            </Button>
            <Button className="slider-next" ref={nextRef} style={{ display: isEnd ? 'none' : 'block' }}>
              Next
            </Button>
          </>
        )}
      </Box>
    </>
  );
};

export default SubSlider;
