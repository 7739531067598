import React from 'react';
import { Container, Flex } from '@radix-ui/themes';

import './style.scss';
import { Link } from 'react-router-dom';

/**
 * Renders the footer component for the application.
 *
 * @returns {JSX.Element} The footer component.
 */
export default function Footer() {
  return (
    <footer>
      <Container size="4">
        <section className="container text-wrap">
          <Flex align="center">
            <div className="policy">
              {/*<a href="/Terms" className="body1 font-bold" title="새창으로 이용약관으로 이동">이용약관</a>*/}
              <Link to={'/privacy'} className="body1 font-bold" title="개인정보처리방침으로 이동">
                개인정보처리방침
              </Link>
            </div>
            <div className="logo">
              <a title="AH\u20B4" href="http://www.wa.or.kr/board/list.asp?BoardID=0006" target="_blank">
                <img src={require('assets/images/common/logo-wa.png')}  alt="(사)한국장애인단체총연합회 한국웹접근성인증평가원 웹 접근성 우수사이트 인증마크(WA인증마크)" className="wa" />
              </a>
              <span className="swedu">SW미래채움</span>
            </div>
          </Flex>
          <address className="body2">주소 : 서울특별시 마포구 월드컵북로 396, 누리꿈스퀘어 연구개발타워 14F 지역SW융합비즈마루</address>
          <p className="body3 copyright">COPYRIGHT SW미래채움. ALL RIGHTS RESERVED.</p>
        </section>
      </Container>
    </footer>
  );
}
