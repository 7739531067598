import React, { useState, useEffect } from 'react';
import { Box, Card, Flex, Text } from '@radix-ui/themes';
import { useSelector } from 'react-redux';
import { selectUserSelectedLocal, selectUserSelectedLocalCode } from 'store/slices/common';

import SubSlider from 'components/common/slider/SubSlider';
import NoData from 'components/common/noData';

const CenterIntroduction = ({ localData }) => {
  // hooks
  const userSelectedLocal = useSelector(selectUserSelectedLocal);
  const userSelectedLocalCode = useSelector(selectUserSelectedLocalCode);

  // state
  const [currentIndexes, setCurrentIndexes] = useState(localData?.childCenter?.map(() => 0) || []);

  // 슬라이드 이동 method
  const handleSlideChange = (index, activeIndex) => {
    setCurrentIndexes(prevIndexes => {
      if (!Array.isArray(prevIndexes)) {
        prevIndexes = [];
      }

      const newIndexes = [...prevIndexes];
      newIndexes[index] = activeIndex;
      return newIndexes;
    });
  };

  useEffect(() => {
    if (localData?.childCenter) {
      setCurrentIndexes(localData.childCenter.map(() => 0));
    }
  }, [localData?.childCenter]);

  return localData ? (
    <>
      <Box mt="var(--space-12)" mb="var(--space-15)">
        <Flex justify="between">
          {localData.basicInfo ? (
            <ul className="info-items">
              {Object.entries(localData.basicInfo)
                // address, phone, opentime만 필터링하고 정렬
                .filter(([key]) => ['address', 'phone', 'opentime'].includes(key))
                .sort(([keyA], [keyB]) => {
                  const order = { address: 1, opentime: 2, phone: 3 }; // 순서 정의
                  return (order[keyA] || Infinity) - (order[keyB] || Infinity); // 정의된 순서대로 정렬
                })
                .map(([key, value], index) => {
                  // 클래스와 타이틀을 매핑
                  const classMap = {
                    address: 'address',
                    phone: 'call',
                    opentime: 'time'
                  };
                  const titleMap = {
                    address: '주소',
                    phone: '전화번호',
                    opentime: '오픈시간'
                  };

                  return (
                    <li key={index} className={`body1 ${classMap[key] || ''}`}>
                      <Flex align="start">
                        <strong className="title">{titleMap[key] || ''}</strong>
                        <Text as="p" className="body1" wrap={'balance'}>
                          {value || '-'}
                        </Text>
                      </Flex>
                    </li>
                  );
                })}
            </ul>
          ) : (
            <Text as="p">센터 정보가 없습니다.</Text>
          )}
        </Flex>
      </Box>

      <Box mb="var(--space-20)">
        <Flex justify="between">
          {localData.additionalInfo.map((item, index) => (
            <Card
              key={index}
              className={`info-wrap ${index === 0 ? 'light-blue' : index === 1 ? 'light-yellow' : index === 2 ? 'light-red' : ''}`}
            >
              <strong className="sub-title">{item.category}</strong>
              <ul className="info-items info-items-block">
                <li className="body1 call">{item.phone || '-'}</li>
                <li className="body1 mail">{item.email || '-'}</li>
              </ul>
            </Card>
          ))}
        </Flex>
      </Box>

      {/* 컨텐츠 CK 에디터 데이터 */}

      <Box pb="var(--space-20)">
        <div dangerouslySetInnerHTML={{ __html: localData.business }} />
      </Box>

      {/* // 컨텐츠 CK 에디터 데이터 */}

      {localData.childCenter.map((item, index) => (
        <Box mb="var(--space-20)" className="slider-wrap" key={index}>
          <Flex justify="between">
            <Box className="slider-text">
              <div className="center-info">
                <div className="image-wrap">
                  <img
                    src={require(`../../assets/images/logo/logo-${userSelectedLocalCode}.svg`)}
                    alt={`${userSelectedLocal}센터 sw미래채움 로고`}
                  />
                </div>
                <Text as="p" className="title4">
                  {item.centerName}
                </Text>
                <Text as="p" className="body1">
                  {item.content}
                </Text>
              </div>
              <div className="text-wrap">
                <React.Fragment>
                  <Text as="p" className="body1 bold">
                    {item.files[currentIndexes[index]]?.fileDisplayName}
                  </Text>
                  <Text as="p" className="body1">
                    {item.files[currentIndexes[index]]?.fileDesc}
                  </Text>
                </React.Fragment>
              </div>
            </Box>
            {/* slide의 indicator 개발 부탁 드립니다. */}
            <SubSlider
              name={item.centerName}
              item={item.files}
              index={index}
              currentIndex={currentIndexes[index] || 0}
              handleSlideChange={handleSlideChange}
            />
          </Flex>
        </Box>
      ))}
    </>
  ) : (
    <NoData />
  );
};

export default CenterIntroduction;
