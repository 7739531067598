import React from 'react';
import { Box, Card, Flex, Grid, Inset, Text } from '@radix-ui/themes';
import { Link, useLocation } from 'react-router-dom';

import './style.scss';
import { localList } from 'app/constants';

export default function BoardImageItems({ itemType, tableData, onCellClick }) {
  const location = useLocation();

  return (
    <Grid columns="4" className={`board-image-items type-${itemType}`}>
      {tableData.map((item, index) => {
        return (
          <Link to={`${location.pathname}/detail/${item?.postNo}`} key={item?.postNo || index}>
            <Card className="board-card">
              {item?.thumbnail === null ? (
                <Box className={`no-image type-${itemType}`}></Box>
              ) : (
                <Inset
                  clip="padding-box"
                  side={itemType === 'list' ? 'left' : 'top'}
                  className="image-box"
                  style={{ backgroundImage: `url(${item?.thumbnail})` }}
                >
                  <img src={item?.thumbnail} alt={item?.title} />
                </Inset>
              )}
              <Box className="card-box">
                <Box className="board-extra">
                  <Text className={`chips local ${localList.find(local => local.name === item?.region)?.en}`}>{item?.region}</Text>
                </Box>
                <Box className="card-head">
                  <Text as="p" className="sub-title ellipsis fix-2line">
                    {item?.title}
                  </Text>
                </Box>
                {/*{props.itemType !== "thumbnail" ? (*/}
                {/*  <Box className="card-body">*/}
                {/*    <Text className="icon-clip">파일명.zip</Text>*/}
                {/*  </Box>*/}
                {/*) : null}*/}
                <Box className="card-footer">
                  <Flex align="center">
                    <Text>{item?.createDate && new Date(item?.createDate).toISOString().split('T')[0]}</Text>
                    {/* [웹접근성] 조회수 아이콘 before -> img 태그로 변경 */}
                    <Box className="view">
                      <img src={require('assets/images/icon/icon-view.svg').default} alt="조회수 아이콘" />
                      <span className="view-count">{item?.viewCount}</span>
                    </Box>
                  </Flex>
                </Box>
              </Box>
            </Card>
          </Link>
        );
      })}
    </Grid>
  );
}
