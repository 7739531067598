import React, { useCallback, useState, useEffect } from 'react';
import { Container, Section } from '@radix-ui/themes';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import Visual from 'components/common/visual';
import Breadcrumbs from 'components/common/breadcrumbs';
import PageTitle from 'components/layout/pageTitle';
import BoardImage from 'components/board/boardImage';

import { instance } from 'api/axios.instance';
import { selectUserSelectedLocalCode } from 'store/slices/common';

// 교육안내 > 교육정보 목록 페이지
export default function Education() {
  const boardType = 'education';
  const pageName = '교육정보';

  // hooks
  const userSelectedLocalCode = useSelector(selectUserSelectedLocalCode);
  const [searchParams] = useSearchParams();

  // state
  const [loading, setLoading] = useState(true); // 로딩 상태
  const [fetchData, setFetchData] = useState({
    list: [],
    pagination: {}
  });

  // 교육 정보 목록 조회 api
  const fetchList = useCallback(async () => {
    setLoading(true);
    try {
      const response = await instance('/post/list', {
        method: 'POST',
        data: {
          boardType,
          pageNo: Number(searchParams.get('page')) || 1,
          pageSize: 8,
          searchType: searchParams.get('searchType'),
          search: searchParams.get('search'),
          inOrder: 'DESC',
          region: userSelectedLocalCode
        }
      });
      setFetchData({
        pagination: response.data.data.pagination,
        list: response.data.data.posts
      });
    } finally {
      setLoading(false);
    }
  }, [searchParams, userSelectedLocalCode]);

  // mount 혹은 searchParams 변경 시 호출
  useEffect(() => {
    fetchList();
  }, [fetchList, searchParams]);

  return (
    <>
      <Visual category={boardType} title={pageName} />
      <Breadcrumbs />
      <Section p="0" mb={'var(--space-35)'} className={boardType}>
        <Container size="4">
          <PageTitle icon={boardType} title={pageName} />
          <BoardImage localFilter boardType={boardType} itemType="poster" tableBody={fetchData} loading={loading} />
        </Container>
      </Section>
    </>
  );
}
