import React, { useCallback, useState, useEffect } from 'react';
import { Container, Section } from '@radix-ui/themes';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import Visual from 'components/common/visual';
import Breadcrumbs from 'components/common/breadcrumbs';
import PageTitle from 'components/layout/pageTitle';
import BoardList from 'components/board/boardList';

import { instance } from 'api/axios.instance';
import { selectUserSelectedLocalCode } from 'store/slices/common';

// 공지사항 > 공지사항 목록 페이지
export default function NoticeList() {
  const boardType = 'notice';
  const pageName = '공지사항';

  // hooks
  const userSelectedLocalCode = useSelector(selectUserSelectedLocalCode);
  const [searchParams] = useSearchParams();

  // state
  const [loading, setLoading] = useState(true); // 로딩 상태
  const [fetchData, setFetchData] = useState({
    list: [],
    pagination: {}
  });
  const tableHeader = [
    { label: '번호', key: 'rowNum', width: '10%' },
    { label: '지역', key: 'region', width: '10%' },
    { label: '제목', key: 'title', width: 'auto', left: true },
    { label: '첨부파일', key: 'isFile', width: '15%' },
    { label: '조회수', key: 'viewCount', width: '10%' },
    { label: '등록일', key: 'createDate', width: '12%' }
  ];

  // 공지사항 목록 조회 api
  const fetchList = useCallback(async () => {
    setLoading(true);
    try {
      const response = await instance('/post/list', {
        method: 'POST',
        data: {
          boardType,
          pageNo: Number(searchParams.get('page')) || 1,
          pageSize: 8,
          searchType: searchParams.get('searchType'),
          search: searchParams.get('search'),
          inOrder: 'DESC',
          region: userSelectedLocalCode
        }
      });
      setFetchData({
        pagination: response.data.data.pagination,
        list: response.data.data.posts
      });
    } finally {
      setLoading(false);
    }
  }, [searchParams, userSelectedLocalCode]);

  // mount 혹은 searchParams 변경 시 호출
  useEffect(() => {
    fetchList();
  }, [searchParams, fetchList]);

  return (
    <>
      <Visual category={boardType} title={pageName} />
      <Breadcrumbs />
      <Section p="0" mb={'var(--space-35)'} className={boardType}>
        <Container size="4">
          <PageTitle icon="bubble" title={pageName} />
          <BoardList localFilter tableHeader={tableHeader} tableBody={fetchData} loading={loading} title={pageName} />
        </Container>
      </Section>
    </>
  );
}
